// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-registration-js": () => import("./../../../src/pages/about-registration.js" /* webpackChunkName: "component---src-pages-about-registration-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-adults-course-js": () => import("./../../../src/pages/adults-course.js" /* webpackChunkName: "component---src-pages-adults-course-js" */),
  "component---src-pages-bus-js": () => import("./../../../src/pages/bus.js" /* webpackChunkName: "component---src-pages-bus-js" */),
  "component---src-pages-children-course-js": () => import("./../../../src/pages/children-course.js" /* webpackChunkName: "component---src-pages-children-course-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-free-course-js": () => import("./../../../src/pages/free-course.js" /* webpackChunkName: "component---src-pages-free-course-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-recruit-js": () => import("./../../../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */),
  "component---src-pages-try-js": () => import("./../../../src/pages/try.js" /* webpackChunkName: "component---src-pages-try-js" */)
}

